import * as React from 'react';
import { Link } from 'gatsby';
import monsta_gangster from '../images/monsta_gangster.gif';

const NotFoundPage = () => {
  return (
    <main>
      <title>404 - Not Found</title>
      <div className="flex flex-col justify-center items-center p-8 text-center">
        <h1 className="text-2xl md:text-6xl font-bold py-4 flex items-end">
          <img src={monsta_gangster} alt="Gangster Monsta" className="w-8 mr-8 md:w-20"/>
          Page not found
          <img src={monsta_gangster} alt="Gangster Monsta" className="w-8 ml-8 md:w-20"/>
        </h1>
        <p className="text-2xl">
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <Link to="/" className="text-2xl underline">
            Click here to go home
          </Link>
          .
        </p>
      </div>
    </main>
  );
};

export default NotFoundPage;
